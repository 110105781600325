import React from "react";
import { Helmet } from "react-helmet";
// import { graphql } from "gatsby";

// const query = graphql`
//   {
//     site {
//       siteMetadata {
//         author
//         siteDesc: description
//         siteUrl
//         siteTitle: title
//       }
//     }
//   }
// `
const SEO = ({ title, description }) => {
  // const { site } = useStaticQuery(query)
  // const { siteDesc, siteTitle, siteUrl, author } = site.siteMetada
  return (
    <Helmet title={title} htmlAttributes={{ lang: "en" }}>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default SEO;
