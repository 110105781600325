import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import SEO from "../components/SEO";
export default ({ data }) => {
  const {
    allStrapiLearningpacks: { nodes: learningPacks },
  } = data;
  return (
    <Layout>
      <SEO title="Home" description="Homepage" />
      <Hero learningPacks={learningPacks} />
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiLearningpacks(sort: { fields: id }) {
      nodes {
        id
        Subject
        Description
        Thumbnail {
          publicURL
        }
      }
    }
  }
`;
