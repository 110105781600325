import React from "react";
import Title from "./Title";
import { graphql, Link, useStaticQuery } from "gatsby";
const LearningPacks = ({ context }) => {
  const query = graphql`
    {
      allStrapiLearningpacks(sort: { fields: ShowOnHomePage }) {
        nodes {
          id
          Subject
          Title
          Thumbnail {
            publicURL
          }
        }
      }
    }
  `;
  const data = useStaticQuery(query);
  const {
    allStrapiLearningpacks: { nodes: learningPacks },
  } = data;

  return (
    // <section>
    <div style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent:"space-around" }}>
      {learningPacks.map((learningPack, index) => {
        if (index < 8) {
          const { id, Subject, Title, Thumbnail } = learningPack;
          return (
            <Link key={index} className="home-service" to="/resources" state={{ subject: Subject }} style={{display:"flex", flexDirection:"column", width:"50%", height:"20%", justifyContent:"center", alignItems:"center", padding: "0px 5px"}}>
              {/* <div key={id} style={{display:"flex", flexDirection:"column"}}> */}
                <h4 style={{display:"flex", minHeight:"32px"}}>{Title}</h4>
                <img style={{display:"flex", height: "75%", width:"80%"}} src={Thumbnail.publicURL} alt={Subject} />
              {/* </div> */}
            </Link>
          );
        }
      })}
    </div>
    // </section>
  );
};

export default LearningPacks;
