import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
// import SocialLinks from "../constants/socialLinks";
import LearningPacks from "./HomePageLearningPacks";
import hero from "../assets/hero.png";
const query = graphql`
  {
    hometext: allStrapiHometext {
      nodes {
        Text
      }
    }
  }
`;

const Hero = ({ learningPacks }) => {
  const {
    hometext: { nodes: hometext },
  } = useStaticQuery(query);
  const { Text } = hometext[0];
  return (
    <div className="home-page-container">
      <div className="home-page">
        <section className="section jobs bg-blue">
          <p className="hero-intro">{Text}</p>
          <div>
            <Link to="resources">
              <img width="60%" src={hero} alt="" />
            </Link>
          </div>

          <Link to="resources" className="btn start-learning">
            Start Learning
          </Link>
          {/* <div className="section-center jobs-center">
          jobs center
          <div className="btn-container">
            btn container
           
          </div>
          <div className="job-info-container">
            job info container
          </div>
        </div> */}
        </section>
        <section className="section bg-white home-learning-packs">
          <LearningPacks context="homepage" />
        </section>
      </div>
    </div>

    // <>
    //   <header className="hero">
    //     <div className="before">
    //       <LearningPacks context="homepage" />
    //     </div>
    //     <div className="section-center hero-center">
    //       <article className="hero-info">
    //         <p className="hero-intro">{Text}</p>
    //         <div>
    //           <div className="hero-logo">
    //             <img className="hero-home-img" src={hero} alt="" />
    //             <Link to="resources" className="btn">
    //               Start Learning
    //             </Link>
    //           </div>
    //         </div>
    //       </article>
    //     </div>
    //   </header>
    // </>
  );
};

export default Hero;
